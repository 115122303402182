import axios from 'axios';
const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get('code');


export const getCompanies = async(index = 0) =>{
    index.pageParam ? index = index.pageParam : index = 0;
    let next;
    let storedMessages = JSON.parse(sessionStorage.getItem('emailsList'));
    let messages = storedMessages[index];
    
    const authToken = localStorage.getItem('authToken');
   // console.log("sending emails", messages);
    
    var options = {
      method: 'POST',
      url: process.env.REACT_APP_EMAIL_URL,
      headers: {
        'Authorization': 'Bearer ' + authToken
      },
      data: {
        messages: messages
      }
    }
    // axios.request(options)

    const response = await axios.request(options)
    // let emails = response.data
    // console.log(response)
    // const secondResponse = await axios.post(process.env.REACT_APP_PROCESS_URL, {
    //       emails: emails
    //     })
    console.log('Length of stored messages');
    console.log(storedMessages.length)
    index == storedMessages.length - 1  ? (next = undefined) : (next = index + 1);
    console.log(next)

    
    return {response: response.data, nextPage: next }
    

}
