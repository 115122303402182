import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SignedInRQ from './states/SignedIn';
import NavBar from './components/navBarHome';
import Hero from './components/hero';
import secureLocalStorage from 'react-secure-storage';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null)

  useEffect(()=>{
    //check if the user has logged in before. If so we can get a valid token from the server
    let authToken = localStorage.getItem('authToken');
    // let cachedEmail = secureLocalStorage.getItem('emailAddr');
    console.log("Auth Token: ", authToken)
    if (authToken){
      //user has signed in before
      setUser(authToken);
      setIsLoggedIn(true);

      var options = {
        method: 'POST',
        url: process.env.REACT_APP_USES_URL,
        data: {
          "token": authToken
        }
      }
      axios.request(options)
    }
    else{
      setIsLoggedIn(false);

      window.location.replace(process.env.REACT_APP_ENTRY_PAGE)
      console.log('No User Id')

    }
  },[])

  // Runs whenever stateVariables change
  useEffect(() => {
    handleEmailFromUrl();
  })

  const authorisation = async () => {
    window.location.replace(process.env.REACT_APP_AUTH_URL);
  }

  const handleEmailFromUrl = () =>{
    console.log('Trying to get code from URL')
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get('user');
    // const email = urlParams.get('email');
    if (user) {
      // console.log('user exists YAY!')
      localStorage.setItem('authToken',user);
      // secureLocalStorage.setItem('emailAddr',email);
      setUser(user);
      setIsLoggedIn(true);
      window.location.replace(process.env.REACT_APP_MAIN_URL);
    }
  }
  
  if (!isLoggedIn) {
    // When not logged in we redirect to the get started page.
    // window.location.replace(process.env.REACT_APP_ENTRY_PAGE);
    // return (
    //   <div className='App' style={{ overflowX: 'clip' }}>
    //     <div className='Background'> </div>
    //     <div className='homeWrapper'>
    //       <NavBar />
    //       <Hero handleClick={authorisation} />
    //     </div>
    //   </div>
    // );
  }
  else if (isLoggedIn) {
    return (
      <div className='App' style={{ overflowX: 'clip' }}>
        <SignedInRQ props ={user}/>
      </div>
    );
  }

  else return (
    null
  )

}

export default App;


/* 

*/