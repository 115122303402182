import React from "react";
import '../scss/main'
import vendeeLogo from '../resources/Vendee-Logo-Blue.svg';

export default function NavBar() {
    return (
        <div className="NavBar">
            <nav className="app">
                <div className="logo">
                    <img src={vendeeLogo} alt="Vendee" style={{ height: '1.5rem' }} />
                </div>
                <div className="gForm">
                    <a href="https://forms.gle/7t2FMBf5mdzwNn958">
                        Give us Feedback
                    </a>
                </div>
            </nav>
            <section className="Banner">
                <h1 className="title">
                    Who has your data?
                </h1>
                <h2 className="subtitle">
                    We've detected that the following companies are holding your data.
                </h2>
            </section>
        </div>
    );
}
