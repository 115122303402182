import React, { useState } from "react";
import vendeeV from '../resources/vendeeVPNG.png';
import removeDuplicates from "../scripts/removeDuplicates";
import notFound from '../resources/not-found.png';
import Popup from "./popup";
import NavBar from "./navBarApp";
import '../scss/styles';
import '../scss/main';


function Interface(props) {
    let senders = props.props;
    let count = 0;
    //console.log(senders);
    senders = removeDuplicates(senders);
    const [isOpen, setIsOpen] = useState(false);
    const [companyData, setCompanyData] = useState({ 'name': null, 'domain': null, 'logo': null });

    const togglePopup = (props) => {
        //console.log('Button Clicked!!!!')
        //console.log(isOpen)
        setIsOpen(!isOpen);
        setCompanyData({ 'name': props.name, 'domain': props.domain, 'logo': props.logo });
    }


    const listItems = senders.map(sender =>

        <div className="card" key={count}>
            <div className="card-image">
                {sender.logo !== '' ?
                    <img src={sender.logo} alt="Company Logo"></img> :
                    <img src={notFound} alt="Company Logo" style={{opacity:'0.6'}}></img>
                }
            </div>
            <div className="card-title">{sender.name}</div>
            <div className="get-started-button" onClick={() => togglePopup(sender)}>
                See More
            </div>

        </div>,


        count = count + 1);

    const headerCard = 
    <div className="card" key={-999}>
            <div className="company-count">
                <h1>{senders.length}</h1>
            </div>
            <div className="card-content">
                This many companies have your Data!
                Scroll to see which ones.
            </div>

    </div>


    return (
        <div className="Wrapper">
            <NavBar />
            <div className="scroll-wrapper"
                div style={{ overflowY: 'auto', overflowX:'none', height: 'inherit', display: 'block' }}>

                <div className="footprint-grid" style={{ marginTop: '2rem' }}>
                    
                    {listItems}
                </div>
            </div>
            {isOpen ? <Popup
                content={<>
                    {companyData.logo !== '' ?
                        <img className="popup-img" src={companyData.logo} alt="Company Logo"></img> :
                        <img className="popup-img" src={notFound} alt="Company Logo" style={{opacity:'0.6'}}></img>
                    }
                    <div className="popup-name">
                        {companyData.name}
                    </div>
                    <div className="popup-text">
                    This Company has your Data!
                    </div>
                    <div className="popup-domain">
                        {companyData.domain}
                    </div>
                    <div className="popup-text">
                         Soon you will be able to see what data they have on you and easily request that they delete your data simply by clicking the button below! 
                    </div>
                </>}
                handleClose={() => togglePopup({'name':null, 'domain':null, 'logo':null})}
            /> :
                null
            }
        </div>
    );
}

export default Interface;