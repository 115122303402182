import React from "react";
import '../scss/main';

export default function Popup(props) {
    return (
        <div className="popup">
            <div className="popup_inner">
                <div className="closeButton" onClick={props.handleClose}>x</div>
                {props.content}
                <div class="double-buttons">

                    <div className="get-started-button" style={{cursor:'not-allowed', backgroundColor:'grey', width:'45%'}}>Delete Your Data!</div>

                    <div className="gForm" style={{ width:'45%'}}>
                        <a href="https://forms.gle/7t2FMBf5mdzwNn958">
                            Give us Feedback
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}
