import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get('code');

export const getEmails = async (userID) => {
    console.log('Get Emails Function Call')
    console.log(`Attempting to authenticate with code ${userID}`)
    var options = {
        method: 'GET',
        url: process.env.REACT_APP_ID_URL + userID,
        //url: fetchURL,    
    }

    const firstResponse = await axios.request(options);
    let emails = firstResponse.data.ids;
    secureLocalStorage.setItem('lastEmailRecieved',emails[emails.length - 1]);
    sessionStorage.setItem('accessToken',firstResponse.data.token);
    return firstResponse.data;
   
}
