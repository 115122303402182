import React, { useEffect, useState } from 'react';
import LoadingScreen from '../components/loading-anim';
import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
} from "@tanstack/react-query";
import HaveEmails from './haveEmails';
import axios from 'axios';


/** 
 * This signed in page is for first time login on a device
 * first we get a list of all emails
 * then we split the emails into a 
 * 
 * 
 */
function SignedIn(props) {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let authToken = props.props;
  const fetchUrl = process.env.REACT_APP_ID_URL;// + authToken;
  useEffect(() => {

    const getEmails = async () =>{
      var options = {
        method: 'GET',

        url: fetchUrl, 
        headers: {
          'Authorization': 'Bearer ' + authToken
        }
        //url: `https://txgzztwu6d.execute-api.eu-west-1.amazonaws.com/test/ids?token=${authToken}`,
        //url: fetchURL,  
      }
      try {

        const response = await axios.request(options);
        console.log("RESPONSE:\n", response)
        setData(response.data);
        setError(null);
        // sessionStorage.set('accessToken',response.data.token)
        return response.data;
      } catch (error) {
        console.log(error)
        //const errorURL = process.env.REACT_APP_ERROR_URL + error.response.status
        switch (error.response.status) {

          case 400:
            setError(400)
            //window.location.replace(errorURL);
            break;
          case 401:
            setError(401)
            // window.location.replace(process.env.REACT_APP_AUTH_URL);
            //local
            //window.location.replace("http://localhost:8000/auth");
            break
          default:
            //window.location.replace("http://localhost:8000/
            //window.location.replace(errorURL);
        }
      } finally {
        setLoading(false);
      }
    };
    getEmails()

  }, [])





// const authToken = props.props;
// console.log(authToken);

// const { isLoading, error, data: fetched } = useQuery({
//   queryKey: ["emailsList"],
//   queryFn: getEmails(authToken),
//   refetchOnWindowFocus: false,
// });






if (loading) {
  return (

    <LoadingScreen />

  );
}

if (error) {
  console.log(error)
  
  const errorURL = process.env.REACT_APP_ERROR_URL + error.response.status
  switch (error.response.status) {

    case 400:
      window.location.replace(errorURL);
      break;
    case 401:
      // window.location.replace(process.env.REACT_APP_AUTH_URL);
      //local
      //window.location.replace("http://localhost:8000/auth");
      break
    default:
      //window.location.replace("http://localhost:8000/
      window.location.replace(errorURL);
  }
}

return (
  <>
    <HaveEmails props={data} />
  </>

)

  /*return (

    

  )*/



}

function SignedInRQ(props) {
  const queryClient = useQueryClient();
  return (
    console.log(props),
    //<h1 className='authToken'>{props.props}</h1>
    <SignedIn props={props.props} />

  );
}






export default SignedInRQ;