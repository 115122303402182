import React, { useEffect, useState } from 'react';
import LoadingScreen from '../components/loading-anim';
import Interface from '../components/interface';
import secureLocalStorage from "react-secure-storage";
import getCompaniesFromSS from '../scripts/secureStorageGetC';
import {
    useQueryClient,
    useQuery,
    useInfiniteQuery,
} from "@tanstack/react-query";
import { getEmails } from '../scripts/getEmails';
import { getCompanies } from '../scripts/getCompanies';
import chunkArray from '../scripts/splitArray';
import removeDuplicates from '../scripts/removeDuplicates';

export default function HaveEmails(props) {
    const [fullList, setFullList] = useState([]);

    let fetched = props.props;

    let emailIDs = chunkArray(fetched.ids, 200);

    
    // sessionStorage.setItem('accessToken', fetched.token);
    sessionStorage.setItem('emailsList', JSON.stringify(emailIDs));




    // const  {isLoading, data: companies } = useQuery({
    //     queryKey: ["companiesList",0],
    //     queryFn: getCompanies(emailIDs, 0),
    //     refetchOnWindowFocus: false,
    //     initialData: getCompaniesFromSS('vendeeCompanyList'),
    //     enabled: !!emailIDs
    //   });

    let index = 0;
    const { data: companiesList,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage, } = useInfiniteQuery({
            queryKey: ["companiesList", index],
            queryFn: getCompanies,
            getNextPageParam: (lastPage) => lastPage.nextPage,
            enabled: !!emailIDs
        })

    useEffect(() => {
        console.log(hasNextPage)
        if(hasNextPage){
            fetchNextPage();
        }

    }, [companiesList]);

    useEffect(() => {

    })




    console.log(companiesList);

    useEffect(() => {
        console.log("setting local storage")
        localStorage.setItem('vendeeCompanyList', companiesList);
        if (companiesList){
            for(const page in companiesList.pages){
                setFullList([...fullList, ...companiesList.pages[page].response]);
                //console.log('full list')
                //console.log(fullList)
            }

        }
        
        
        // console.log(fullList)
    }, [companiesList]);


    if (isLoading) {
        return (

            <LoadingScreen />

        );
    }

    if (error) {
        console.log(error)
        return(<>
            <Interface props={fullList} />
        </>)
        // const errorURL = process.env.REACT_APP_ERROR_URL + error.response.status
        // switch (error.response.status) {

        //     case 400:
        //         window.location.replace(errorURL);
        //         break;
        //     case 401:
        //         window.location.replace(process.env.REACT_APP_AUTH_URL);
        //         //local
        //         //window.location.replace("http://localhost:8000/auth");
        //         break
        //     default:
        //         //window.location.replace("http://localhost:8000/
        //         window.location.replace(errorURL);
        // }
    }

    return (
        <>
            <Interface props={fullList} />
            {isFetching | isFetchingNextPage ?
            <LoadingScreen />:
            null}
        </>
    )
}