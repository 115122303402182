
export default function removeDuplicates(arr) {


    arr = arr.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.name === value.name && t.domain === value.domain
        ))
    )

    return arr;
}